<template>
  <!----<Carousel class="new-home-slider">
    <Slide v-for="(slide, index) in homeSlider" :key="index">
      <div class="carousel__item">
        <div class="banner">
          <img :src="slide.image" alt="" class="w-100 zoom-out" />
          <div class="banner-text">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-8 col-md-10" data-aos="fade-up">
                  <h4>{{ translationText.text.homeName }}</h4>
                  <h1 v-html="translationText.text.homeDescription"></h1>
                  <router-link
                    :to="{ name: 'ShowDetails', params: { id: 1 } }"
                    class="btn btn-outline-wh"
                    >{{ translationText.text.readMore }}</router-link
                  >                  
                  <router-link to="/product" class="btn btn-dark">{{
                    translationText.text.shopNow
                  }}</router-link>                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slide>

    <template #addons>
      <Navigation />
     
    </template>
  </Carousel>-->
  <div class="xmass-banner">
  <video src="/assets/images/christmas/banner.mp4" type=video/mp4 width="100%" style="height:auto !important;" loop="" muted="" playsinline="" autoplay=""></video>
 
  <div class="banner-contents-xmass">
    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <h2>{{ translationText.text.homeName }}</h2>
          <p>{{translationText.text.homeDescription}}</p>
          <router-link
                    :to="{ name: 'ShowDetails', params: { id: 1 } }"
                    class="btn btn-outline-wh"
                    >{{ translationText.text.readMore }}</router-link
                  >
          <router-link to="/product" class="btn btn-dark">{{
            translationText.text.shopNow
          }}</router-link>
        </div>
        <div class="col-md-12 featured-products">
          <h5>{{ translationText.text.homeFeatureText }} :</h5>
          <ul>
            <li><img src="/assets/images/christmas/partner6.png" alt=""></li>          
            <li><img src="/assets/images/christmas/partners2.png" alt=""></li>
            <li><img src="/assets/images/christmas/partners3.png" alt=""></li>
            <li><img src="/assets/images/christmas/partners4.png" alt=""></li>
            <li><img src="/assets/images/christmas/partners5.png" alt=""></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
  <section
    class="lets-us-sec text-center"
    v-html="supplyQuality.page_content"
  ></section>
  <section class="red-bg" style="background:url(/assets/images/christmas/red_bg.jpg);"
    v-html="cymcorpAcheivement.page_content"
  ></section>

<section class="products-area" style="background:#EEEBEB;">
    <div class="container">
        <div class="row justify-content-center" data-aos="fade-up">
            <div class="title col-lg-8 text-center">
                <h2>Holistic Packages for Your Health and Wellness</h2>                
            </div>
        </div>
        <div class="flex-holistic">

            <template v-for="(holistic,index) in holistic.products">
                 <div class="single-product-box">
                  <div class="product-image">
                      <img :src="holistic.thumbnail_url" alt="" class="img-responsive">
                      <img :src="holistic.images_url[0]" alt="" class="w-100 hover-gallery-image">
                      <!-- <a class="quick-view">QUICK VIEW</a> -->
                      <router-link
                      class="quick-view"
                      :to="{ name: 'ShowDetails', params: { id: holistic.id } }"
                      >{{ translationText.text.quickView }}</router-link
                    >
                  </div>
                  <div class="product-dtls">               
                      <h3>
                         <router-link
                      
                      :to="{ name: 'ShowDetails', params: { id: holistic.id } }"
                      >{{ holistic.name }}</router-link
                    >

                      </h3>
                      <p class="actual-price">
                      <del>${{ holistic.sale_price }}</del>
                      ${{ holistic.suggested_retail_price }}</p>
                      <!-- <p class="discount-price">For valued Scholar: $43.60</p> -->
                  </div>
              </div>

            </template>
           
            
        </div>
    </div>  
</section>
<section
    class="lets-us-sec help-you"
    v-html="cymcorpHelpYou.page_content"
  ></section>

  <section class="products-area">
    <div class="container">
      <div v-html="naturalProduct.page_content"></div>      
      <div class="row">
        <template v-if="products.products.length > 0">
          <template v-for="(product, index) in products.products">
            <div
              class="col-lg-4 col-md-4 col-6"
              data-aos="fade-up"
              v-if="product.display_shop == 'yes'"
            >
              <div class="single-product-box">
                <div class="product-image">
                  <img
                    :src="product.thumbnail_url"
                    alt=""
                    class="img-responsive"
                  />
                  <img
                    :src="product.images_url[0]"
                    alt=""
                    class="w-100 hover-gallery-image"
                  />
                  <router-link
                    class="quick-view"
                    :to="{ name: 'ShowDetails', params: { id: product.id } }"
                    >{{ translationText.text.quickView }}</router-link
                  >
                </div>
                <div class="product-dtls">
                  <h3>
                    <router-link
                      :to="{ name: 'ShowDetails', params: { id: product.id } }"
                      >{{ product.name }}</router-link
                    >
                  </h3>
                  <div class="price">
                  <template
                    v-if="
                      product.sale_price !=
                      product.suggested_retail_price
                    "
                  >
                    <del>${{ product.sale_price }}</del>
                  </template>
                  ${{ product.suggested_retail_price }}                   
                </div>

                </div>
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
  </section>

  <section class="gurantee-full-wrap primary-bg p-0">
    <div class="barana-img">
      <img :src="guarantee.page_banner_url" alt="" class="w-100" />
    </div>
    <div class="barana-text" v-html="guarantee.page_content"></div>
  </section>

  <section class="product-review">
    <div class="container">
      <div class="row justify-content-center">
        <div class="title col-lg-7 text-center" data-aos="fade-up">
          <h5 v-html="peopleSaying.page_subheading"></h5>
          <h2 v-html="peopleSaying.name"></h2>
        </div>
        <div class="col-12" data-aos="fade-up">     
          <Splide
            aria-labelledby="video-example-heading"
            :options="options"
            :extensions="extensions"
          >
            <SplideSlide
              v-for="(id, index) in videos"
              :key="id"
              :data-splide-youtube="id"
            >
              <img
                :src="`https://i3.ytimg.com/vi/${id}/maxresdefault.jpg`"
                :alt="`YouTube Sample ${index + 1}`"
              />
            </SplideSlide>
          </Splide>

          <div class="btn-center text-center">
            <router-link :to="{ name: 'Testimonials' }" class="btn btn-dark">{{
              translationText.text.allReviews
            }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="stories-sec">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-md-6" data-aos="fade-up">
          <div class="title">
            <h2>{{ translationText.text.realStoryText }}</h2>
          </div>

          <Carousel
            paginationColor="gray"
            paginationActiveColor="red"
            :itemsToShow="1"
            class="stories-slider"
            :wrapAround="false"
            :transition="500"
          >
            <Slide v-for="(slide, index) in storySilder" :key="index">
              <div class="carousel__item">
                <img :src="slide.image" alt="" class="quote" />

                <p v-if="index == 0">
                  {{ translationText.text.storySliderDescription }}
                </p>
                <p v-if="index == 1">
                  {{ translationText.text.storySliderDescription1 }}
                </p>
                <p v-if="index == 2">
                  {{ translationText.text.storySliderDescription2 }}
                </p>
                <h6>{{ slide.name }}</h6>
              </div>
            </Slide>

            <template #addons>
              <Pagination />
            </template>
          </Carousel>

          <div class="btn-left mt-md-5 mt-4">
            <router-link to="/testimonials" class="link line-animation">{{
              translationText.text.realStoryText
            }}</router-link>
          </div>
        </div>
        <div class="col-md-6 d-none d-md-block">
          <figure>
            <img
              src="assets/images/stories-img2.jpg"
              alt=""
              class="w-100 radius20"
            />
          </figure>
        </div>
      </div>
    </div>
  </section>
  <section class="Athletes-area">
  <div class="container">
    <div class="row justify-content-center mb-3">
      <div class="col-md-10 text-center mb-3">
        <h2>Check What Real Athletes Are Saying About Their Experience With NexPro Cell Tech</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="Athletes-slider">
          <Carousel
            paginationColor="gray"
            paginationActiveColor="red"
            :itemsToShow="1"
            class="stories-slider"
            :wrapAround="false"
            :transition="500"
          >
            <Slide v-for="(aslide, index) in athletesSlider" :key="index">
              <div class="carousel__item">
                <div class="Athletes-contents">
                <div class="left-image">
                  <img :src="aslide.image" alt="" class="img-responsive">
                </div>
                <div class="right-dtls">
                  <div class="contents">
                    <p>{{ aslide.description }}</p>
                    <h4>{{ aslide.name }}</h4>
                    <p class="short-p">{{ aslide.designation }}</p>
                    <p class="short-p">{{ aslide.acheivemenet }}</p>
                  </div>
                </div>
              </div>
              </div>
            </Slide>

            <template #addons>
              <Pagination />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
  </div>
</section>
<section
    class="video-area"
    v-html="realAthletesReview.page_content"
  ></section>

  <section class="blog-sec pt-0">
    <div class="container">
      <div class="row justify-content-center">
        <div class="title col-lg-7 text-center" data-aos="fade-up">
          <h2>{{ translationText.text.doscover }}</h2>
          <p>{{ translationText.text.doscoverText }}</p>
        </div>
        <div class="col-lg-6 col-md-5" data-aos="fade-right">
          <div class="blg-img">
            <img src="assets/images/blog.jpg" alt="" />
          </div>
        </div>
        <div class="col-lg-6 col-md-7" data-aos="fade-left">
          <Carousel
            paginationColor="gray"
            paginationActiveColor="red"
            :itemsToShow="1"
            class="stories-slider"
            :wrapAround="true"
            :transition="500"
          >
            <Slide v-for="(slide, index) in discoverSilder" :key="index">
              <div class="carousel__item">
                <h4>
                  <a href="#" v-if="index == 0">{{
                    translationText.text.doscoverSliderName
                  }}</a>
                  <a href="#" v-if="index == 1">{{
                    translationText.text.doscoverSliderName1
                  }}</a>
                  <a href="#" v-if="index == 2">{{
                    translationText.text.doscoverSliderName2
                  }}</a>
                </h4>
                <!-- <h6 class="date-meta">
                  <i class="ri-calendar-2-line"></i> {{ slide.dateTime }}
                  <a href="javascript: ;"
                    ><i class="ri-message-2-line"></i> 0 Comments</a
                  >
                </h6> -->
                <p v-if="index == 0">
                  {{ translationText.text.doscoverSliderDescription }}
                </p>
                <p v-if="index == 1">
                  {{ translationText.text.doscoverSliderDescription1 }}
                </p>
                <p v-if="index == 2">
                  {{ translationText.text.doscoverSliderDescription2 }}
                </p>

                <!-- <a href="javascript: ;" class="btn btn-dark">{{
                  translationText.text.readMore
                }}</a> -->
              </div>
            </Slide>

            <template #addons>
              <Pagination />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
  </section>

  <section class="gurantee-full-wrap new-full-about p-0">
    <div class="barana-img">
      <img :src="aboutCompany.page_banner_url" alt="" class="w-100" />
    </div>
    <div class="barana-text" v-html="aboutCompany.page_content"></div>
  </section>
</template>

<script setup>
import { defineComponent, ref, onMounted, defineProps, watch } from "vue";
import { storeToRefs } from "pinia";
import { Carousel, Pagination, Slide, Navigation } from "vue3-carousel";
import ProductListQuiz from "../components/Product/ProductListQuiz";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { Video } from "@splidejs/splide-extension-video";
import "@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css";
import { userLanguage } from "../stores/language";
import { useLoginStore } from "../stores/login";

const userLogin = useLoginStore();
const props = defineProps(["baseURL"]);
const videos = [
  "8B4fJZRkeOc",
  "YZ__M7qvPDI",
  "tsiCFTVemnA",
  "zgvm82FjfbE",
  "Vh98rKreIR0",
];
const userLanguages = userLanguage();
const { userCountry } = storeToRefs(userLogin);
const { languageCode } = storeToRefs(userLanguages);

const options = ref({
  rewind: true,
  type: "loop",
  perPage: 3,
  pagination: false,
  gap: "2em",
  breakpoints: {
    1199: {
      perPage: 2,
    },
    767: {
      perPage: 1,
    },
  },
  //heightRatio: 0.5625,
});
const extensions = ref({ Video });

const supplyQuality = ref("");
const naturalProduct = ref("");
const guarantee = ref("");
const peopleSaying = ref("");
const aboutCompany = ref("");
const helpYou = ref("");
const cymcorpHelpYou = ref("");
const realAthletesReview = ref("");
const cymcorpAcheivement = ref("");


const products = ref(
  {
    // products:{
    products: [],
  }
  //}
);

const holistic = ref({
    products: [],
  });

const translationTextEn = ref({
  text: {
    quickView: "QUICK VIEW",
    realStoryText: "Success Stories From Real People",
    readMore: "Read More",
    shopNow: "Shop Now",
    allReviews: "SEE ALL Reviews",
    readTestimonials: "Read More TESTIMONIALS",
    doscover: "Discover Our Latest Blogs",
    doscoverText:
      "Natural Immune System, Beauty Tips, The Newest Beauty Science Discoveries and More!",
    homeName: "Unlock Vitality, Immunity, and Longevity",
    homeDescription:
      "Enhance your healthspan with Cymcorp's breakthrough solution for immunity, vitality, and whole-body wellness.",
    homeFeatureText: "FEATURED IN",
    doscoverSliderName: "How to boost your immune system",
    doscoverSliderDescription:
      "On the whole, your immune system does a remarkable job of defending you against disease-causing microorganisms. But sometimes it fails: A germ invades successfully and makes you sick.",
    doscoverSliderName1: "10 reasons why hydration is important",
    doscoverSliderDescription1:
      "Staying hydrated is a simple yet critical part of maintaining good health. But most people just aren’t getting the fluids they need every day.",
    doscoverSliderName2: "Why gut health is important?",
    doscoverSliderDescription2:
      "The gut breaks down the foods you eat and absorbs nutrients that support your body's functions. Your gut is the foundation of everything.",
    storySliderDescription:
      "Greetings!! When I tried the GSH eye wash in my eye that was being doctored for possible infections & allergies- the eye cleared up overnight!!! So I am wondering if the same could be put in the ears. About 18 months ago I got major hearing loss and tinnitus at the same time a few weeks after a bad fall. Hope you can help!!",
    storySliderDescription1:
      "Two years ago I was pregnant with my first son. About six months in my pregnancy, I started to have terrible heartburns which would make me lose a lot of sleep – it was getting problematic for both, me and my son's health.As many know, while being pregnant, you cannot take any prescription drugs or pain killers. I went to my doctor with my issue and he suggested a natural solution which would be harmless to both of us. I started taking Triozyme then and it worked instantly! Even to this day, my family put the prescription drugs and pain killers to rest and we only use Triozyme for any digestion issues.",
    storySliderDescription2:
      "My name is Tom Carranco I am 77 years old and I was on Viprox and in Oct. of 2018 I went into the hospital for a small procedure. I was in recovery and the nurses rammed my feet and lower legs two times with a gurney that had someone on it. I was hurt pretty bad and ended up in rehab for just over a month. And to this day I am recovered as a was taking Viprox before and thisis why my recovery was faster then it would have been, thanks be to God.Now I am still taking Viprox and I went to see my doctor in January 2019 and he took me all of My high Blood Pressure pills I was taking and about four other different pills praise be to God!  ",    
  },
  language: "",
});


const translationText = ref({
  text: {
    quickView: "QUICK VIEW",
    realStoryText: "Success Stories From Real People",
    readMore: "Read More",
    shopNow: "Shop Now",
    allReviews: "SEE ALL Reviews",
    readTestimonials: "Read More TESTIMONIALS",
    doscover: "Discover Our Latest Blogs",
    doscoverText:
      "Natural Immune System, Beauty Tips, The Newest Beauty Science Discoveries and More!",
    homeName: "Unlock Vitality, Immunity, and Longevity",
    homeDescription:
      "Enhance your healthspan with Cymcorp's breakthrough solution for immunity, vitality, and whole-body wellness.",
    homeFeatureText: "FEATURED IN",
    doscoverSliderName: "How to boost your immune system",
    doscoverSliderDescription:
      "On the whole, your immune system does a remarkable job of defending you against disease-causing microorganisms. But sometimes it fails: A germ invades successfully and makes you sick.",
    doscoverSliderName1: "10 reasons why hydration is important",
    doscoverSliderDescription1:
      "Staying hydrated is a simple yet critical part of maintaining good health. But most people just aren’t getting the fluids they need every day.",
    doscoverSliderName2: "Why gut health is important?",
    doscoverSliderDescription2:
      "The gut breaks down the foods you eat and absorbs nutrients that support your body's functions. Your gut is the foundation of everything.",
    storySliderDescription:
      "Greetings!! When I tried the GSH eye wash in my eye that was being doctored for possible infections & allergies- the eye cleared up overnight!!! So I am wondering if the same could be put in the ears. About 18 months ago I got major hearing loss and tinnitus at the same time a few weeks after a bad fall. Hope you can help!!",
    storySliderDescription1:
      "Two years ago I was pregnant with my first son. About six months in my pregnancy, I started to have terrible heartburns which would make me lose a lot of sleep – it was getting problematic for both, me and my son's health.As many know, while being pregnant, you cannot take any prescription drugs or pain killers. I went to my doctor with my issue and he suggested a natural solution which would be harmless to both of us. I started taking Triozyme then and it worked instantly! Even to this day, my family put the prescription drugs and pain killers to rest and we only use Triozyme for any digestion issues.",
    storySliderDescription2:
      "My name is Tom Carranco I am 77 years old and I was on Viprox and in Oct. of 2018 I went into the hospital for a small procedure. I was in recovery and the nurses rammed my feet and lower legs two times with a gurney that had someone on it. I was hurt pretty bad and ended up in rehab for just over a month. And to this day I am recovered as a was taking Viprox before and thisis why my recovery was faster then it would have been, thanks be to God.Now I am still taking Viprox and I went to see my doctor in January 2019 and he took me all of My high Blood Pressure pills I was taking and about four other different pills praise be to God!  ",    
  },
  language: "",
});

const homeSlider = ref([
  {
    image: "/assets/images/Home-Banner.jpg",
  },
  {
    image: "/assets/images/UNIVIE-banner4.jpg",
  },
  {
    image: "/assets/images/Triozyme-banner3.jpg",
  },
  {
    image: "/assets/images/Immunity-banner2.jpg",
  },
]);
const silder = ref([
  "/assets/images/review-img1.jpg",
  "/assets/images/review-img2.jpg",
  "/assets/images/review-img3.jpg",
  "/assets/images/review-img3.jpg",
]);
const storySilder = ref([
  {
    name: "Elaine J.",
    description:
      "Greetings!! When I tried the GSH eye wash in my eye that was being doctored for possible infections & allergies- the eye cleared up overnight!!! So I am wondering if the same could be put in the ears. About 18 months ago I got major hearing loss and tinnitus at the same time a few weeks after a bad fall. Hope you can help!!",
    image: "/assets/images/quote.png",
    video: "https://youtu.be/8B4fJZRkeOc",
  },
  {
    name: "Carolyn T.",
    description:
      "Two years ago I was pregnant with my first son. About six months in my pregnancy, I started to have terrible heartburns which would make me lose a lot of sleep – it was getting problematic for both, me and my son's health.As many know, while being pregnant, you cannot take any prescription drugs or pain killers. I went to my doctor with my issue and he suggested a natural solution which would be harmless to both of us. I started taking Triozyme then and it worked instantly! Even to this day, my family put the prescription drugs and pain killers to rest and we only use Triozyme for any digestion issues.",
    image: "/assets/images/quote.png",
    video: "https://youtu.be/8B4fJZRkeOc",
  },
  {
    name: "Thomas Carranco",
    description:
      "Two years ago I was pregnant with my first son. About six months in my pregnancy, I started to have terrible heartburns which would make me lose a lot of sleep – it was getting problematic for both, me and my son's health.As many know, while being pregnant, you cannot take any prescription drugs or pain killers. I went to my doctor with my issue and he suggested a natural solution which would be harmless to both of us. I started taking Triozyme then and it worked instantly! Even to this day, my family put the prescription drugs and pain killers to rest and we only use Triozyme for any digestion issues.",
    image: "/assets/images/quote.png",
    video: "https://youtu.be/8B4fJZRkeOc",
  },
]);
const discoverSilder = ref([
  {
    dateTime: "22 July, 23",
  },
  {
    dateTime: "01 August, 23",
  },
  {
    dateTime: "15 September, 23",
  },
]);

const athletesSlider = ref([
      {
        name: "Sean McColl, Canada",
        designation: "Professional Rock Climber, Sports Climbing (Bouldering, Lead, Combined)",
        acheivemenet: "8 times world champion",
        description:
          "“NexPro Cell Tech is my first and ONLY fitness supplement, I use it every day and could feel the benefits in under 2 weeks. I recently came back from a French Cup in Valence and I felt like everything clicked at the competition. I felt like I had extra energy, I had trained well and I can only think of the supplements I've been taking. I think my body is really liking it. Une-Vie is the product I take every morning alongside my GSH Whey Protein. It hydrates me every day and promotes better nutrient absorption.”",
        image: "/assets/images/athalatics-img.png"
      },
      {
        name: "Maggy Dubé-Laroche, Canada",
        designation: "Sport Climber",
        acheivemenet: "Canadian Champion (Speed Climbing)",
        description:
          "“NexPro Cell Tech is the only fitness supplements in my daily routine. Since adding the GSH Complex immunity supplement and Une Vie Hydrating solution to my routine, I’ve noticed a huge difference. I feel more energized during workouts, recover faster, and haven’t had to worry about getting sick as often.”",
        image: "/assets/images/athalatics-img2.png"
      },
]);

const fetchDataFromAPI = async () => {
  const languageUrl = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  const reqUserType = userLogin.checkUserType
    ? "/" + userLogin.checkUserType
    : "/" + "customer";
  const apiUrl = props.baseURL + "products" + reqUserType + languageUrl;
  //
  console.log("home product api url ", apiUrl);
  axios
    .get(apiUrl)
    .then((response) => {
      products.value = response.data;
      console.log(response.data);
      //console.log("product data",this.products.products.length);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};

const fetchDataFromAPIHolistic  = async () => {
  const languageUrl = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  const reqUserType = userLogin.checkUserType
    ? "/" + userLogin.checkUserType
    : "/" + "customer";
  const apiUrl = props.baseURL + "products/holistic" + reqUserType + languageUrl;
  //const apiUrl = "https://cymcorp.us/management/api/v1/" + "products/holistic" + reqUserType + languageUrl;
  
  //
  console.log("home product api url ", apiUrl);
  axios
    .get(apiUrl)
    .then((response) => {
      holistic.value = response.data;
      console.log(response.data);
      //console.log("product data",this.products.products.length);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};


const getSupplyQuality = async () => {
  const languageUrl = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  const apiUrl =
    props.baseURL + "page/supplier-of-true-quality-life-products" + languageUrl;

  axios
    .get(apiUrl)
    .then((response) => {
      if (response.data.page_details.length > 0) {
        supplyQuality.value = response.data.page_details[0];
      } else {
        supplyQuality.value = "";
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};

const getNaturalProduct = async () => {
  const languageUrl = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  const apiUrl =
    props.baseURL +
    "page/our-products-are-developed-by-experienced-team" +
    languageUrl;

  axios
    .get(apiUrl)
    .then((response) => {
      if (response.data.page_details.length > 0) {
        naturalProduct.value = response.data.page_details[0];
      } else {
        naturalProduct.value = "";
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};

const guaranteed = async () => {
  const languageUrl = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  const apiUrl = props.baseURL + "page/guaranteed" + languageUrl;

  axios
    .get(apiUrl)
    .then((response) => {
      if (response.data.page_details.length > 0) {
        guarantee.value = response.data.page_details[0];
      } else {
        guarantee.value = "";
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};

const getPeopleSaying = async () => {
  const languageUrl = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  const apiUrl =
    props.baseURL +
    "page/see-what-real-people-are-saying-about-our-products" +
    languageUrl;

  axios
    .get(apiUrl)
    .then((response) => {
      if (response.data.page_details.length > 0) {
        peopleSaying.value = response.data.page_details[0];
      } else {
        peopleSaying.value = "";
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};

const getAboutCompany = async () => {
  const languageUrl = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  const apiUrl = props.baseURL + "page/about-our-company" + languageUrl;

  axios
    .get(apiUrl)
    .then((response) => {
      if (response.data.page_details.length > 0) {
        aboutCompany.value = response.data.page_details[0];
      } else {
        aboutCompany.value = "";
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};

const getQuizeText = async () => {
  const languageUrl = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  const apiUrl =
    props.baseURL +
    "page/we-can-help-you-by-guiding-you-to-a-good-solid-health" +
    languageUrl;

  axios
    .get(apiUrl)
    .then((response) => {
      if (response.data.page_details.length > 0) {
        helpYou.value = response.data.page_details[0];
      } else {
        helpYou.value = "";
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};
const getCymcorpHelpYouText = async () => {
  const languageUrl = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  const apiUrl =
    props.baseURL +
    "page/how-can-cymcorp-help-you" +
    languageUrl;

  axios
    .get(apiUrl)
    .then((response) => {
      if (response.data.page_details.length > 0) {
        cymcorpHelpYou.value = response.data.page_details[0];
      } else {
        cymcorpHelpYou.value = "";
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};
const getRealAthletesReviewText = async () => {
  const languageUrl = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  const apiUrl =
    props.baseURL +
    "page/real-athletes-review" +
    languageUrl;

  axios
    .get(apiUrl)
    .then((response) => {
      if (response.data.page_details.length > 0) {
        realAthletesReview.value = response.data.page_details[0];
      } else {
        realAthletesReview.value = "";
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};
const getCymcorpAcheivementText = async () => {
  const languageUrl = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  const apiUrl =
    props.baseURL +
    "page/cymcorp-acheivement" +
    languageUrl;

  axios
    .get(apiUrl)
    .then((response) => {
      if (response.data.page_details.length > 0) {
        cymcorpAcheivement.value = response.data.page_details[0];
      } else {
        cymcorpAcheivement.value = "";
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};



const onReady = async () => {
  this.$refs.youtube.playVideo();
};

const translatText = () => {
  // console.log(props.baseURL);
  // const languageUrl = userLanguages.getLanguageCode
  //   ? "/" + userLanguages.getLanguageCode
  //   : "";
  translationTextEn.value.language = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  if(userLanguages.getLanguageCode != 'en'){

    const apiUrl = props.baseURL + "translateany";
    axios
      .post(apiUrl, translationTextEn.value)
      .then((response) => {
        console.log("response trnasate :", response.data);
        translationText.value = response.data;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }
  else{
        translationText.value = translationTextEn.value;
  }
  
};

watch(languageCode, () => {
  fetchDataFromAPI();
  getSupplyQuality();
  getNaturalProduct();
  guaranteed();
  getPeopleSaying();
  getAboutCompany();
  getQuizeText();
  getCymcorpHelpYouText();
  getRealAthletesReviewText();
  translatText();
  fetchDataFromAPIHolistic();
  getCymcorpAcheivementText();
});

watch(userCountry, () => {
  console.log("isLoggedIn ref changed, do something!");
  console.log("userLanguages.getLanguageCode", userLanguages.getLanguageCode);
  fetchDataFromAPI();
  getSupplyQuality();
  getNaturalProduct();
  guaranteed();
  getPeopleSaying();
  getAboutCompany();
  getQuizeText();
  getCymcorpHelpYouText();
  getRealAthletesReviewText();
  translatText();
  fetchDataFromAPIHolistic();
  getCymcorpAcheivementText();
});

onMounted(() => {
  fetchDataFromAPIHolistic();
  fetchDataFromAPI();
  getSupplyQuality();
  getNaturalProduct();
  guaranteed();
  getPeopleSaying();
  getAboutCompany();
  getQuizeText();
  getCymcorpHelpYouText();
  getRealAthletesReviewText();
  getCymcorpAcheivementText();
  translatText();
});
</script>
